import yahooLogo from "./assets/images/yahoo-test.png";
import marketWatchLogo from "./assets/images/marketwatch-test.png";
import benzingaLogo from "./assets/images/benzinga-logo.png";
import { SubSectionTitle } from "./common/SubSectionTitle";
import Image from "next/image";

const FeaturedInSection = () => (
  <div className="bg-gray-900">
    <div className="justify-between px-10 py-6 mx-auto xl:py-24 xl:flex max-w-7xl">
      <div className="py-8 xl:py-0">
        <SubSectionTitle>Featured In</SubSectionTitle>
      </div>
      <div>
        <div className="flex flex-col items-center justify-center space-y-12 xl:space-y-0 xl:flex-row xl:space-x-24">
          <div className="w-32">
            <Image src={yahooLogo} alt="Yahoo Finance" />
          </div>
          <div className="w-48">
            <Image src={marketWatchLogo} alt="Market Watch" />
          </div>
          <div className="w-48">
            <Image src={benzingaLogo} alt="Benzinga" className="grayscale" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function HomeBanner() {
  return <FeaturedInSection />;
}
